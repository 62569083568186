import LoanApi from "../services/api/Loans";

if (
  window.location.pathname === "/loan-agreement" ||
  window.location.pathname === "/loan-agreement/"
) {

  $(function () {

    let customerId = $('#customer_id').val();
    let loanId = $('#loan_id').val();
    let agreementType = "Html";
    let forSigning = "true";

    LoanApi.getLoanAgreement(customerId, loanId, agreementType, forSigning)
      .then((response) => {
        if (response.success && response.data.html != undefined) {
          let parser = new DOMParser();
          let doc = parser.parseFromString(response.data.html, "text/html");
          let html = doc.body.innerText.replaceAll('jQuery', '$'); // scope the jQuery code to use $ instead of jQuery
          $('#hidden_agreementSequenceItem').val(response.data.agreement_sequence_item_key);
          $('#loanAgreementHtmlWpr').html(html);
          $('#loanAgreementForm').show();
        } else {
          $('#loanAgreementHtmlWpr').html("<p class='text-danger text-center pb-5'>We were unable to pull your Loan Agreement.</p>");
        }

        $('#eSignOverlay').hide(); // hide loading overlay
      });
  });

  $('#loanAgreementForm').on('submit', function (e) {
    $('#agreement_error_msg').hide();

    let signatureDataJson = PlmAgreement.getSignatureDataJson();
    if (signatureDataJson === '') {
      $('#agreement_error_msg').show();
      return false;
    }
    $("#hidden_signatureDataJson").val(signatureDataJson);
    return true;
  });

}