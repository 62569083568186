/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// Sass
// --
import "../sass/app.scss";

import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";
import "@fortawesome/fontawesome-free/js/brands";

// JavaScript
// --

// Dependencies
import $ from 'jquery';
window.jQuery = window.$ = $;

require('bootstrap');
require("core-js/stable");

window.flatpickr = require('flatpickr');
window.validate = require('jquery-validation');

require('jquery-serializeobject');
require("regenerator-runtime/runtime");
require("./dependencies/autocomplete-address");
require("./dependencies/jquery.validate.custom");

// Pages
require('./pages/contact');
require('./pages/home');
require('./pages/market-authorization');
require('./pages/loan-agreement');
require("./pages/loan-application");

require('./global');
require('./extend/string');